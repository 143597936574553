import React, { useEffect, useState } from "react";
import "./Test14.css";
import g3 from "./graphic3.png";

const Test14 = () => {
  const [userCount, setUserCount] = useState(0);
  const [completedRequests, setCompletedRequests] = useState(0);
  const [postedRequests, setPostedRequests] = useState(0);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_SERVER_URL;

        // Fetch User Count
        const userResponse = await fetch(`${baseUrl}/api/admin/users/count`);
        const userData = await userResponse.json();
        setUserCount(userData.count);

        // Fetch Completed Requests
        const completedResponse = await fetch(
          `${baseUrl}/api/admin/requests/completed`
        );
        const completedData = await completedResponse.json();
        setCompletedRequests(completedData.count);

        // Fetch Posted Requests
        const postedResponse = await fetch(
          `${baseUrl}/api/admin/requests/active`
        );
        const postedData = await postedResponse.json();
        setPostedRequests(postedData.count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <br />
      <br />
      <div className="test-14-section-heading">REQUESTO</div>
      <br />
      <br />
      <br />
      <div className="test-14-section-description">
        <p>Where your wildest dreams meet real-life adventure.</p>
        <p>Dare to dream, Requesto dares to deliver.</p>
        <p>Join the guild, embark on the extraordinary.</p>
      </div>
      <br />
      <br />
      <br />
      <div className="test-14-container">
        {/* Item 1 */}
        <div className="test-14-item">
          <div className="test-14-count-item">
            <div className="test-14-count-item-heading">User Count</div>
            {userCount}
          </div>
          <div className="test-14-count-item-description">
            Number of users that have signed up on Requesto
          </div>
        </div>

        {/* Item 2 */}
        <div className="test-14-item">
          <div className="test-14-count-item">
            <div className="test-14-count-item-heading">Completed</div>
            {completedRequests}
          </div>
          <div className="test-14-count-item-description">
            Number of completed requests on Requesto
          </div>
        </div>

        {/* Item 3 */}
        <div className="test-14-item">
          <div className="test-14-count-item">
            <div className="test-14-count-item-heading">Posted</div>
            {postedRequests}
          </div>
          <div className="test-14-count-item-description">
            Number of requests posted on Requesto
          </div>
        </div>
        <br />
      </div>

      <div className="test-14-aboutus">
        <div className="test-14-aboutus-heading">OUR MISSION</div>
      </div>

      <p className="test-14-aboutus-description">
        WHY: To empower individuals to live out their dreams and experience the
        thrill of adventure, by bridging the gap between fantasy and reality.
      </p>
      <p className="test-14-aboutus-description">
        HOW: By connecting users with a network of passionate and skilled
        "adventurers" who can fulfill a diverse range of requests, no matter how
        extraordinary.
      </p>
      <p className="test-14-aboutus-description">
        WHAT: Requesto is a platform where users can submit requests and real
        adventurers will take on the challenge to make it happen.
      </p>
    </div>
  );
};

export default Test14;
