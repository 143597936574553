import FL3BotsList from "./FL3BotsList";
import FL3Form from "./FL3Form";
import FL3Pagination from "./FL3Pagination";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./FL3.css";

const FL3 = () => {
  const [bots, setBots] = useState([]);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    assignment: "",
    subject: "",
    price: "",
    deadline: "",
    details: "",
  });
  const [templates, setTemplates] = useState([
    {
      name: "Math Assignment",
      assignment: "Solve the math problems",
      subject: "Mathematics",
      price: 100,
      deadline: new Date().toISOString().slice(0, 10), // Default to current date
      details: "Complete the set of 20 algebra problems.",
    },
    {
      name: "Science Research",
      assignment: "Write a research paper",
      subject: "Science",
      price: 200,
      deadline: new Date().toISOString().slice(0, 10), // Default to current date
      details: "Research on renewable energy sources.",
    },
    {
      name: "History Essay",
      assignment: "Write an essay on World War II",
      subject: "History",
      price: 150,
      deadline: new Date().toISOString().slice(0, 10), // Default to current date
      details: "Discuss the major events of World War II.",
    },
  ]);
  const [numRequests, setNumRequests] = useState(0);
  const [intervalTime, setIntervalTime] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [botsPerPage] = useState(9);
  const [searchTerm, setSearchTerm] = useState("");
  const [completedRequests, setCompletedRequests] = useState(0);
  const intervalIdRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBots = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/bots`
        );
        setBots(response.data);
      } catch (error) {
        setMessage("Error fetching bots.");
      } finally {
        setLoading(false);
      }
    };

    fetchBots();

    // Set the default deadline to 1 day from now
    const defaultDeadline = new Date();
    defaultDeadline.setDate(defaultDeadline.getDate() + 1); // Adds 1 day to current date
    setFormData((prevState) => ({
      ...prevState,
      deadline: defaultDeadline.toISOString().slice(0, 10), // Format to YYYY-MM-DD
    }));
  }, []);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle template selection to auto-fill the form fields
  const handleTemplateSelect = (templateIndex) => {
    const selectedTemplate = templates[templateIndex];
    setFormData({
      assignment: selectedTemplate.assignment,
      subject: selectedTemplate.subject,
      price: selectedTemplate.price,
      deadline: selectedTemplate.deadline,
      details: selectedTemplate.details,
    });
  };

  // Validate if the deadline is in the future
  const isDeadlineValid = (deadline) => {
    const deadlineDate = new Date(deadline);
    return deadlineDate > new Date(); // Check if the deadline is in the future
  };

  // Function to post a random request
  const postRandomRequest = async () => {
    if (!isDeadlineValid(formData.deadline)) {
      setMessage("Deadline must be in the future.");
      return;
    }

    try {
      const bot = bots[Math.floor(Math.random() * bots.length)];
      const template = templates[Math.floor(Math.random() * templates.length)];

      const requestData = {
        ...template,
        sender: bot.name,
        sender_email: bot.email,
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request`,
        requestData
      );

      setMessage("Request posted successfully!");
      setCompletedRequests((prev) => prev + 1);
    } catch (error) {
      setMessage("Error posting request.");
      console.error(error);
    }
  };

  // Function to start posting requests at intervals
  const startPostingRequests = () => {
    setCompletedRequests(0);
    let counter = 0;
    intervalIdRef.current = setInterval(() => {
      if (counter < numRequests) {
        postRandomRequest();
        counter++;
      } else {
        clearInterval(intervalIdRef.current);
      }
    }, intervalTime);
  };

  // Function to stop posting requests
  const stopPostingRequests = () => {
    clearInterval(intervalIdRef.current);
  };

  // Handle posting request from a specific bot
  const handlePostRequest = async (botId) => {
    if (!isDeadlineValid(formData.deadline)) {
      setMessage("Deadline must be in the future.");
      return;
    }

    try {
      const bot = bots.find((b) => b._id === botId);
      if (!bot) return;

      const requestData = {
        ...formData,
        sender: bot.name,
        sender_email: bot.email,
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request`,
        requestData
      );

      setMessage("Request posted successfully!");
      setFormData({
        assignment: "",
        subject: "",
        price: "",
        deadline: "",
        details: "",
      });
    } catch (error) {
      setMessage("Error posting request.");
      console.error(error);
    }
  };

  // Handle deleting a bot
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/bots/${id}`);
      setBots(bots.filter((bot) => bot._id !== id));
      setMessage("Bot deleted successfully.");
    } catch (error) {
      setMessage("Error deleting bot.");
    }
  };

  // Handle sign in for a bot
  const handleSignIn = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/signin`,
        { email, password }
      );

      const { token } = response.data;
      localStorage.setItem("token", token);

      const decodedToken = jwtDecode(token);
      localStorage.setItem("name", decodedToken.user.name);
      localStorage.setItem("email", decodedToken.user.email);
      navigate("/");

      setMessage(`Signed in successfully as: ${decodedToken.user.name}`);
    } catch (error) {
      setMessage("Error signing in.");
      console.error(error);
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Get current bots for pagination
  const indexOfLastBot = currentPage * botsPerPage;
  const indexOfFirstBot = indexOfLastBot - botsPerPage;
  const currentBots = bots
    .filter((bot) => bot.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(indexOfFirstBot, indexOfLastBot);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="fl3-container">
      <h1 className="fl3-title">Manage Bots</h1>
      {message && <p className="fl3-message">{message}</p>}

      <FL3Form
        formData={formData}
        templates={templates}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        handleTemplateSelect={handleTemplateSelect}
        numRequests={numRequests}
        setNumRequests={setNumRequests}
        intervalTime={intervalTime}
        setIntervalTime={setIntervalTime}
        startPostingRequests={startPostingRequests}
        stopPostingRequests={stopPostingRequests}
      />

      <div className="fl3-request-tracker">
        <p>Completed Requests: {completedRequests}</p>
      </div>

      <div className="fl3-form">
        <input
          type="text"
          placeholder="Search Bots..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {loading ? (
        <p className="fl3-loading">Loading...</p>
      ) : (
        <>
          {currentBots.length === 0 ? (
            <p className="fl3-message">No bots found.</p>
          ) : (
            <FL3BotsList
              bots={currentBots}
              handleDelete={handleDelete}
              handleSignIn={handleSignIn}
              handlePostRequest={handlePostRequest}
            />
          )}

          <FL3Pagination
            totalBots={bots.length}
            botsPerPage={botsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default FL3;
