import React from "react";

const FilterComponent = ({
  filter,
  setFilter,
  searchTerm,
  setSearchTerm,
  showFilters,
  setShowFilters,
  selectedFilter,
  setSelectedFilter,
}) => {
  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setFilter(filter);
    setShowFilters(false);
  };

  return (
    <div className="filter-component">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="filter-button"
          onClick={() => setShowFilters(!showFilters)}
        >
          {selectedFilter ? selectedFilter : "filter▾"}
        </button>

        {showFilters && (
          <div className="ap-dropdown-menu">
            <ul>
              <li onClick={() => handleFilterSelect("All")}>All</li>
              <li onClick={() => handleFilterSelect("Selling")}>Selling</li>
              <li onClick={() => handleFilterSelect("Hiring")}>Hiring</li>
              <li onClick={() => handleFilterSelect("Delivery")}>Delivery</li>
              <li onClick={() => handleFilterSelect("Rent")}>Rent</li>
              <li onClick={() => handleFilterSelect("Mentorship")}>
                Mentorship
              </li>
              <li onClick={() => handleFilterSelect("Guidance")}>Guidance</li>
              <li onClick={() => handleFilterSelect("Assignment")}>
                Assignment
              </li>
              <li onClick={() => handleFilterSelect("Custom")}>Custom</li>
            </ul>
          </div>
        )}
      </div>

      <div className="filter-options">
        <button
          onClick={() => setFilter("all")}
          className={filter === "all" ? "active" : ""}
        >
          All Requests
        </button>
        <button
          onClick={() => setFilter("posted")}
          className={filter === "posted" ? "active" : ""}
        >
          Posted Requests
        </button>
        <button
          onClick={() => setFilter("accepted")}
          className={filter === "accepted" ? "active" : ""}
        >
          Applied Requests
        </button>
      </div>
    </div>
  );
};

export default FilterComponent;
