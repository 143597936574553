import CustomRequestModal from "../RequestTemplates/CustomRequestModal";
import Test12 from "./Test12";
import arrow from "./arrow.png";
import videosrc from "./vid.mp4";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Test9 = ({ contentType = "poster" }) => {
  const [isCustomRequestModalOpen, setIsCustomRequestModalOpen] =
    useState(false);

  // Arrays for poster and acceptor content
  const posterContent = [
    {
      headingTop: "GET",
      headingMiddle: "SOLUTIONS",
      headingBottom: "NOW!",
    },
    {
      description:
        "We at Requesto believe in making fantasy into reality. This is based on the concept of adventure guild from fanatasy settings. So if you have any requests you can share to us and real adventurers will solve it for you",
    },
  ];

  const acceptorContent = [
    {
      headingTop: "START",
      headingMiddle: "EARNING",
      headingBottom: "NOW!",
    },
    {
      description:
        "We at Requesto believe in making fantasy into reality. This is based on the concept of adventure guild from fanatasy settings. So if you want to start an adventure pick a request and embark now!",
    },
  ];

  // Decide content based on prop
  const content = contentType === "poster" ? posterContent : acceptorContent;
  const heroClass =
    contentType === "poster" ? "hero-aboutus-poster" : "hero-aboutus-acceptor";

  return (
    <div className={`test-4 ${heroClass}`}>
      <div className="test-4-homepage">
        <div className="test-4-heading-top">{content[0].headingTop}</div>
        <div className="test-4-heading-top-middle">
          {content[0].headingMiddle}
        </div>
        <div className="test-4-heading-top">{content[0].headingBottom}</div>

        {/* Video Section */}

        <div className="test-4-additional-content">
          <p className="test-4-description">{content[1].description}</p>
        </div>

        <div className="test-4-video-container">
          <video className="test-4-video" autoPlay muted loop playsInline>
            <source src={videosrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* <Test12 /> */}

        {/* Typing Section - Commented Out */}
        {/* <div className="test-4-text-section">
          <h1 className="test-4-heading">{text}</h1>
        </div> */}

        {/* Description Section */}

        {/* Buttons Section */}
        <div className="test-4-buttons">
          {contentType === "poster" ? (
            <button
              onClick={() => setIsCustomRequestModalOpen(true)}
              className="test-4-post-now-btn"
            >
              <div className="test-4-post-now-btn-text"> Post Now</div>
              <img src={arrow} className="test-4-post-now-arrow" />
            </button>
          ) : (
            <Link to="/assignmentrequests" className="test-4-apply-now-btn">
              Earn Now
              <img src={arrow} className="test-4-post-now-arrow" />
            </Link>
          )}
        </div>
      </div>

      {/* Modal */}
      <CustomRequestModal
        showModal={isCustomRequestModalOpen}
        closeModal={() => setIsCustomRequestModalOpen(false)}
      />
    </div>
  );
};

export default Test9;
