import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./F.css";

const SignIn = ({ showSignInModal, closeSignInModal }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to sign in");
      }

      // After successful sign-in, in the sign-in handler
      localStorage.setItem("showSignInNotification", "true");

      const result = await response.json();
      localStorage.setItem("token", result.token);

      const decodedToken = jwtDecode(result.token);
      localStorage.setItem("name", decodedToken.user.name);
      localStorage.setItem("email", decodedToken.user.email);

      // alert("Sign in successful");
      closeSignInModal();

      navigate("/requesto-homepage");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to sign in. Please try again.");
    }
  };

  const isFormValid = () => {
    const { email, password } = formData;
    return email && password;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp > currentTime) {
          setIsAuthenticated(true);
          navigate("/requesto-homepage");
        } else {
          navigate("/requesto-homepage");
        }
      } catch (error) {
        console.error("Token decoding error", error);
        localStorage.removeItem("token");
      }
    }
  }, []);

  return (
    <>
      <div className={`modal-overlay ${showSignInModal ? "show" : ""}`}>
        <div className="modal-content">
          <div className="new-sign-up-1">
            <div className="new-sign-up-2">
              <a href="/" className="new-sign-up-3">
                <i className="new-sign-up-4"></i>
              </a>
              <h3 className="new-sign-up-5">Sign in with Email</h3>
              <span className="close-button" onClick={closeSignInModal}>
                &times;
              </span>
            </div>
            <form
              name="signin"
              className="new-sign-up-6"
              onSubmit={handleSubmit}
            >
              <div className="new-sign-up-7">
                <fieldset className="new-sign-up-8">
                  <legend className="new-sign-up-9">Sign in with l</legend>
                  <div className="sub-error"></div>
                  <p className="new-sign-up-10">
                    <input
                      type="text"
                      name="email"
                      required
                      className="new-sign-up-11"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label className="new-sign-up-12">Email address</label>
                    <span className="new-sign-up-13"></span>
                  </p>
                  <p className="new-sign-up-10">
                    <input
                      type="password"
                      required
                      name="password"
                      className="new-sign-up-14"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <span className="new-sign-up-15">
                      <i className="new-sign-up-4"></i>Must be 6-18 characters
                      long.
                    </span>
                    <label className="new-sign-up-16">Password</label>
                    <label className="_fly">
                      <input type="checkbox" className="new-sign-up-17" />
                      {/* <i className="new-sign-up-18">SHOW</i>
                      <i className="new-sign-up-19">HIDE</i> */}
                    </label>
                    <span className="new-sign-up-20"></span>
                  </p>
                  <div className="new-sign-up-21"></div>
                  <button
                    type="submit"
                    className="new-sign-up-22"
                    disabled={!isFormValid()}
                  >
                    Sign In
                  </button>
                  {/* <p className="new-sign-up-23">Don't have an account? 
                    <a href="/" title="Sign up" className="new-sign-up-24">Sign up</a>
                  </p> */}
                </fieldset>
              </div>
            </form>
          </div>
          <span className="new-sign-up-25">
            <span className="new-sign-up-26">© 2024</span>
            <span className="new-sign-up-26">
              {" "}
              Requesto all rights reserved,{" "}
            </span>
            <Link
              to="/privacy"
              title="terms of service"
              className="new-sign-up-27"
            >
              Privacy Policy
            </Link>
            .
          </span>
        </div>
      </div>
    </>
  );
};

export default SignIn;
