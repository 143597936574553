import React, { useState, useEffect } from "react";
import "./Admin.css";

const Admin = () => {
  const [userNames, setUserNames] = useState([]);
  const [filteredUserNames, setFilteredUserNames] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [showUserNames, setShowUserNames] = useState(false);

  useEffect(() => {
    fetchData();

    const refreshButton = document.getElementById("refreshButton");
    if (refreshButton) {
      refreshButton.addEventListener("click", fetchData);
    }

    return () => {
      if (refreshButton) {
        refreshButton.removeEventListener("click", fetchData);
      }
    };
  }, []);

  const fetchData = () => {
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const endpoints = [
      { url: `${baseUrl}/api/admin/users/count`, element: "userCount" },
      {
        url: `${baseUrl}/api/admin/requests/completed`,
        element: "completedRequests",
      },
      {
        url: `${baseUrl}/api/admin/requests/active`,
        element: "activeRequests",
      },
      { url: `${baseUrl}/api/admin/users/premium`, element: "premiumUsers" },
    ];

    endpoints.forEach(({ url, element }) => {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          const countElement = document.getElementById(element);
          if (countElement) {
            countElement.textContent = data.count;
          }
        })
        .catch((err) => console.error(`Error fetching ${element}:`, err));
    });
  };

  const fetchUserNames = () => {
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    fetch(`${baseUrl}/api/admin/users/names`)
      .then((res) => res.json())
      .then((data) => {
        setUserNames(data);
        setFilteredUserNames(data);
        setShowUserNames((prev) => !prev);
      })
      .catch((err) => console.error("Error fetching user names:", err));
  };

  // Filter and Search Handlers
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    applyFilters(e.target.value, filter);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    applyFilters(search, e.target.value);
  };

  const applyFilters = (searchTerm, filterOption) => {
    let filtered = [...userNames];

    if (filterOption !== "All") {
      filtered = filtered.filter((user) => user.type === filterOption);
    }

    if (searchTerm) {
      filtered = filtered.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredUserNames(filtered);
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <button id="refreshButton" className="refresh-button">
        Refresh Data
      </button>
      <div className="dashboard-stats">
        <div className="stat-card" onClick={fetchUserNames}>
          <h2>Total Users</h2>
          <p id="userCount">-</p>
        </div>
        <div className="stat-card">
          <h2>Completed Requests</h2>
          <p id="completedRequests">-</p>
        </div>
        <div className="stat-card">
          <h2>Active Requests</h2>
          <p id="activeRequests">-</p>
        </div>
        <div className="stat-card">
          <h2>Premium Users</h2>
          <p id="premiumUsers">-</p>
        </div>
      </div>
      {showUserNames && (
        <div className="user-list">
          <h3>User Names</h3>
          {/* Search Bar and Filter */}
          <div className="search-filter">
            <input
              type="text"
              placeholder="Search by name..."
              value={search}
              onChange={handleSearchChange}
              className="search-bar"
            />
            {/* <select
              value={filter}
              onChange={handleFilterChange}
              className="filter-dropdown"
            >
              <option value="All">All Users</option>
              <option value="Premium">Premium Users</option>
              <option value="Regular">Regular Users</option>
            </select> */}
          </div>

          {/* Scrollable List */}
          <div className="scrollable-list">
            <ul>
              {filteredUserNames.map((user, index) => (
                <li key={index}>{user.name}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
