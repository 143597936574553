import React from "react";


const SliderToggle = ({ isPoster, setIsPoster }) => {
  return (
    <div className="test-7-post-acceptor-slider">
      <div
        className={`test-7-toggle-container ${
          isPoster ? "test-7-poster" : "test-7-acceptor"
        }`}
      >
        <button
          className={`test-7-toggle-button ${
            !isPoster ? "test-7-active" : ""
          }`}
          onClick={() => setIsPoster(false)}
        >
          Post
        </button>
        <button
          className={`test-7-toggle-button ${
            isPoster ? "test-7-active" : ""
          }`}
          onClick={() => setIsPoster(true)}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default SliderToggle;
