import profile from "./images/navbar_profile.png";
import React from "react";

const ProfilePopup = ({ name, email, onClose, onLogout }) => {
  return (
    <div className="modal-overlay-ap">
      <div className="modal-content-ap">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="profile-header">
          <div>
            <img src={profile} className="profile-picture" alt="Profile" />
          </div>
          <h2>User Profile</h2>
        </div>
        <p>
          <strong>Name:</strong> {name}
        </p>
        <p>
          <strong>Email:</strong> {email}
        </p>
        <button className="logout-button" onClick={onLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default ProfilePopup;
