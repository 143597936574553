import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./AssignmentRequest.css";
import FilterComponent from "./FilterComponent";
import Notification from "./Notification";
import RequestItem from "./RequestItem";
import {
  faArrowLeft,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssignmentRequest = () => {
  const [requests, setRequests] = useState([]);
  const [name, setName] = useState(localStorage.getItem("name"));
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [applicantPopupVisible, setApplicantPopupVisible] = useState(false);
  const [applicantToAccept, setApplicantToAccept] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotification1, setShowNotification1] = useState(false);
  const [showNotification2, setShowNotification2] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const handleChat = (name) => {
    function stringToHash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    const uniqueIdentifier = `${name}-${selectedRequest._id}-${selectedRequest.createdAt}`;
    const uniqueHash = stringToHash(uniqueIdentifier);
    localStorage.setItem("generatedCode", uniqueHash);

    navigate(`/chatroom`);
  };

  useEffect(() => {
    if (location.state && location.state.filter) {
      setFilter(location.state.filter);
    }

    const fetchRequests = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/academic-request`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch requests");
        }
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error("Failed to fetch requests:", error);
      }
    };

    fetchRequests();
  }, [location.state]);

  const handleBack = () => {
    navigate("/requesto-homepage");
  };
  const hasUserApplied = () => {
    return selectedRequest.applicants.some(
      (applicant) =>
        applicant.email === localStorage.getItem("email") &&
        applicant.status === "pending"
    );
  };

  const openApplicantPopup = (applicant) => {
    setApplicantToAccept(applicant);
    setApplicantPopupVisible(true);
  };

  const handleApply = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/apply/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applicant: { name, email: localStorage.getItem("email") },
          }),
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 2500);
        }, 300);
        const updatedRequest = await response.json();
        setRequests(
          requests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );
        setSelectedRequest(updatedRequest);

        setSelectedRequest((prev) => ({
          ...prev,
          hasApplied: true,
        }));

        alert(
          "Applied successfully! Your application will expire after 24 hours if not accepted."
        );
      } else {
        console.error("Failed to apply to the request");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleWithdrawRequest = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/withdraw/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification1(true);
          setTimeout(() => {
            setShowNotification1(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
        setRequests(requests.filter((request) => request._id !== id));
        setSelectedRequest(null);
      } else {
        console.error("Failed to withdraw the request");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = (request) => {
    setSelectedRequest(request);
    setApplicants(request.applicants || []);
    console.log(request.applicants); // Log the entire applicants array
  };

  const closePopup = () => {
    setSelectedRequest(null);
    setApplicantPopupVisible(false); // Close the request-popup when closing request details
  };

  const isRequestPoster = selectedRequest && selectedRequest.sender === name;
  const isRequestAcceptor = selectedRequest && selectedRequest.acceptor == name;

  const filteredRequests = requests
    .filter((request) => {
      switch (filter) {
        case "posted":
          return request.sender === name;
        case "accepted":
          return request.acceptor === name;
        case "Selling":
        case "Hiring":
        case "Delivery":
        case "Rent":
        case "Mentorship":
        case "Guidance":
        case "Assignment":
        case "Custom":
          return request.subject === filter;
        case "All":
          return true;
        default:
          return true;
      }
    })
    .filter(
      (request) =>
        request.assignment.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
  const handleAcceptApplicant = async (applicantId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/accept-applicant/${selectedRequest._id}/${applicantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification2(true);
          setTimeout(() => {
            setShowNotification2(false);
          }, 2500);
        }, 300);
        const updatedRequest = await response.json();
        setRequests(
          requests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );
        setSelectedRequest(updatedRequest);
        alert(
          "You have accepted an applicant. The Request will be removed in 24 hours from now."
        );
        setApplicantPopupVisible(false); // Close the request-popup after accepting
      } else {
        const errorData = await response.json();
        console.error("Failed to accept the applicant:", errorData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="stocks-container">
      <div className="back-button" onClick={handleBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>

      <div className="header">
        <h1>Request Board</h1>
      </div>
      <FilterComponent
        filter={filter}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />

      <div className="stocks-list">
        {filteredRequests
          .slice()
          .reverse()
          .map((request, index) => (
            <React.Fragment key={request._id}>
              <RequestItem request={request} handleClick={handleClick} />
              {index < filteredRequests.length - 1 && (
                <div className="divider"></div>
              )}
            </React.Fragment>
          ))}
      </div>

      {selectedRequest && (
        <div className="request-popup-container">
          <div className="request-popup-content">
            <div className="request-popup-header">
              <h1>{selectedRequest.assignment}</h1>
              <p className="request-popup-subtitle">
                {selectedRequest.subject}
              </p>
              <p className="request-popup-description">
                {selectedRequest.details}
              </p>
              <FontAwesomeIcon
                icon={faXmark}
                className="request-popup-close-icon"
                onClick={closePopup}
              />
            </div>
            <div className="request-popup-details">
              <p>
                <strong>Price:</strong> ₹{selectedRequest.price}
              </p>
              <p>
                <strong>Deadline:</strong> {selectedRequest.deadline}
              </p>
              <p>
                <strong>Posted By:</strong> {selectedRequest.sender}
              </p>
              <p>
                <strong>Posted At:</strong>{" "}
                {new Date(selectedRequest.createdAt).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </p>

              <p>
                <strong>Accepted By:</strong> {selectedRequest.acceptor}
              </p>
              {(isRequestPoster || isRequestAcceptor) &&
                selectedRequest.acceptor !== "none" && (
                  <button
                    onClick={() => handleChat(selectedRequest.acceptor)}
                    className="view-applicant-button"
                  >
                    Chat
                  </button>
                )}
            </div>

            {isRequestPoster && selectedRequest.acceptor === "none" && (
              <div className="request-popup-applicants">
                <h3>Applicants:</h3>
                {applicants.length > 0 ? (
                  <div className="applicants-list">
                    {applicants.map((applicant) => (
                      <div key={applicant._id} className="applicant-item">
                        <p>
                          <strong>Name:</strong> {applicant.name}
                        </p>

                        <button
                          onClick={() => openApplicantPopup(applicant)}
                          className="view-applicant-button"
                        >
                          View Details
                        </button>
                        <div>&nbsp;&nbsp;</div>
                        <button
                          onClick={() => handleChat(applicant.name)}
                          className="view-applicant-button"
                        >
                          Chat
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No applicants yet.</p>
                )}
                <button
                  onClick={() => handleWithdrawRequest(selectedRequest._id)}
                  className="withdraw-button"
                >
                  Withdraw Request
                </button>
              </div>
            )}

            {applicantPopupVisible && applicantToAccept && (
              <div className="applicant-request-popup">
                <div className="applicant-request-popup-content">
                  <h2>Accept Applicant</h2>

                  <p>
                    <strong>Name:</strong> {applicantToAccept.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {applicantToAccept.email}
                  </p>
                  <p>
                    <strong>Status:</strong> {applicantToAccept.status}
                  </p>
                  <button
                    onClick={() => handleAcceptApplicant(applicantToAccept._id)}
                    className="accept-applicant-button"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => setApplicantPopupVisible(false)}
                    className="close-request-popup-button"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {!isRequestPoster &&
              selectedRequest.acceptor === "none" &&
              !hasUserApplied() && (
                <div className="request-popup-footer">
                  <button
                    onClick={() => handleApply(selectedRequest._id)}
                    className="accept-button"
                  >
                    <FontAwesomeIcon icon={faCheck} /> Apply
                  </button>
                </div>
              )}

            {!isRequestPoster &&
              selectedRequest.acceptor === "none" &&
              hasUserApplied() && (
                <div className="request-popup-footer">
                  <button
                    onClick={() => handleChat(name)}
                    className="view-applicant-button"
                  >
                    Chat
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
      <Notification
        showNotification={showNotification}
        message="Applied Successfully"
      />
      <Notification
        showNotification={showNotification1}
        message="Withdrawn Successfully"
      />
      <Notification
        showNotification={showNotification2}
        message="Applicant Selected"
      />
    </div>
  );
};

export default AssignmentRequest;
