import React from "react";
import "./Test15.css";

const Test15 = ({ isPoster }) => {
  // Array for FAQ content based on the user type (poster or acceptor)
  const faqContent = [
    {
      userType: "acceptor",
      faqs: [
        {
          question: "How do I post a request?",
          answer:
            "To post a request, simply fill out the form and submit it. Make sure to include all necessary details.",
        },
        {
          question: "What happens after I post a request?",
          answer:
            "Once posted, your request will be available for acceptors to apply. You can review applicants and choose the best one.",
        },
      ],
    },
    {
      userType: "poster",
      faqs: [
        {
          question: "How can I apply for a request?",
          answer:
            "Browse through the requests posted by posters. Choose the one that suits you and apply for it.",
        },
        {
          question: "What happens after I apply for a request?",
          answer:
            "Once you apply, the poster will review your application and either accept or reject your request.",
        },
      ],
    },
  ];

  // Find the content for the correct user type
  const currentFaqs = faqContent.find(
    (item) => item.userType === (isPoster ? "poster" : "acceptor")
  ).faqs;

  return (
    <div className="test-15-container">
      {/* Header */}
      <div className="test-15-header">
        <div>WHAT</div>
        <div>THE</div>
        <div>FAQ'S</div>
      </div>

      {/* FAQ Section */}
      <div className="test-15-faqs">
        {currentFaqs.map((faq, index) => (
          <div key={index} className="test-15-faq">
            <div className="test-15-faq-question">{faq.question}</div>
            <div className="test-15-faq-answer">{faq.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Test15;
