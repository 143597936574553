import React from "react";

const FL3Form = ({
  formData,
  templates,
  setFormData,
  handleInputChange,
  handleTemplateSelect,
  numRequests,
  setNumRequests,
  intervalTime,
  setIntervalTime,
  startPostingRequests,
  stopPostingRequests,
}) => {
  return (
    <div className="fl3-form">
      <h2>Post a Request</h2>

      {/* Template Selector */}
      <select
        onChange={(e) => handleTemplateSelect(e.target.value)}
        defaultValue=""
      >
        <option value="" disabled>
          Select Template
        </option>
        {templates.map((template, index) => (
          <option key={index} value={index}>
            {template.name}
          </option>
        ))}
      </select>

      <input
        type="text"
        name="assignment"
        value={formData.assignment}
        onChange={handleInputChange}
        placeholder="Assignment"
      />
      <input
        type="text"
        name="subject"
        value={formData.subject}
        onChange={handleInputChange}
        placeholder="Subject"
      />
      <input
        type="number"
        name="price"
        value={formData.price}
        onChange={handleInputChange}
        placeholder="Price"
      />
      <input
        type="date"
        name="deadline"
        value={formData.deadline}
        onChange={handleInputChange}
      />
      <textarea
        name="details"
        value={formData.details}
        onChange={handleInputChange}
        placeholder="Details"
      ></textarea>

      {/* Input for number of requests and interval time */}
      <div className="fl3-interval-inputs">
        <input
          type="number"
          value={numRequests}
          onChange={(e) => setNumRequests(e.target.value)}
          placeholder="Number of Requests"
          min="1"
        />
        <input
          type="number"
          value={intervalTime}
          onChange={(e) => setIntervalTime(e.target.value)}
          placeholder="Interval Time (ms)"
          min="1000"
        />
      </div>

      {/* Buttons to start and stop posting requests */}
      <button onClick={startPostingRequests}>Start Posting Requests</button>
      <button onClick={stopPostingRequests}>Stop Posting Requests</button>
    </div>
  );
};

export default FL3Form;