import React from "react";

const FL3BotsList = ({ bots, handleDelete, handleSignIn, handlePostRequest }) => {
    
  return (
    <div className="fl3-list">
      {bots.map((bot) => (
        <div key={bot._id} className="fl3-item">
          <p className="fl3-name">Name: {bot.name}</p>
          <p className="fl3-email">Email: {bot.email}</p>
          <button
            className="fl3-delete-button"
            onClick={() => handleDelete(bot._id)}
          >
            Delete
          </button>
          <button
            className="fl3-signin-button"
            onClick={() => handleSignIn(bot.email, bot.password)}
          >
            Sign In
          </button>
          <button
            className="fl3-post-button"
            onClick={() => handlePostRequest(bot._id)}
          >
            Post Request
          </button>
        </div>
      ))}
    </div>
  );
};

export default FL3BotsList;