import {
  faArrowLeft,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { all } from "axios";
import {
  Book,
  Clock,
  DollarSign,
  GitPullRequest,
  TaskIcon,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Test12.css";

const Test12 = () => {
  const [requests, setRequests] = useState([]);
  const [name, setName] = useState(localStorage.getItem("name"));
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [applicants, setApplicants] = useState([]); // State for applicants
  const navigate = useNavigate();
  const location = useLocation();
  const [applicantPopupVisible, setApplicantPopupVisible] = useState(false); // Default to false
  const [applicantToAccept, setApplicantToAccept] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotification1, setShowNotification1] = useState(false);
  const [showNotification2, setShowNotification2] = useState(false);

  const Badge = ({ children, variant = "secondary" }) => {
    const badgeClass =
      variant === "success" ? "badge badge-success" : "badge badge-secondary";

    return <span className={badgeClass}>{children}</span>;
  };

  const getDaysFromDeadline = (deadline) => {
    const days = deadline.split(" ")[0]; // This will extract the number before 'days'
    return `${days} days`;
  };

  const handleChat = (name) => {
    function stringToHash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    // Create a unique identifier by combining applicant name, request ID, and createdAt timestamp
    const uniqueIdentifier = `${name}-${selectedRequest._id}-${selectedRequest.createdAt}`;
    const uniqueHash = stringToHash(uniqueIdentifier);
    localStorage.setItem("generatedCode", uniqueHash);
    // localStorage.setItem("name_test", uniqueIdentifier);

    // Example: Navigate to a chat page with the applicant's details
    navigate(`/chatroom`);

    // Alternatively, if using a chat request-popup:
    // setChatPopupVisible(true);
    // setChatApplicant(applicant);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/academic-request`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch requests");
        }
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error("Failed to fetch requests:", error);
      }
    };

    fetchRequests();
  }, [location.state]);

  const handleBack = () => {
    navigate("/requesto-homepage");
  };
  const hasUserApplied = () => {
    return selectedRequest.applicants.some(
      (applicant) =>
        applicant.email === localStorage.getItem("email") &&
        applicant.status === "pending"
    );
  };

  const handleAccept = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/accept/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ acceptor: name }),
        }
      );

      if (response.ok) {
        const updatedRequest = await response.json();
        setRequests(
          requests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );
        setSelectedRequest(updatedRequest);
      } else {
        console.error("Failed to accept the request");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openApplicantPopup = (applicant) => {
    setApplicantToAccept(applicant);
    setApplicantPopupVisible(true);
  };

  const handleApply = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/apply/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applicant: { name, email: localStorage.getItem("email") },
          }),
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
        const updatedRequest = await response.json();
        setRequests(
          requests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );
        setSelectedRequest(updatedRequest);

        // Show success alert
        // alert("Applied successfully!");

        // Update button state to reflect application
        setSelectedRequest((prev) => ({
          ...prev,
          hasApplied: true,
        }));

        alert(
          "Applied successfully! Your application will expire after 24 hours if not accepted."
        );
      } else {
        console.error("Failed to apply to the request");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDecline = async (applicantId) => {
    // Implement decline functionality here
  };

  const handleWithdrawRequest = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/withdraw/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification1(true);
          setTimeout(() => {
            setShowNotification1(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
        setRequests(requests.filter((request) => request._id !== id));
        setSelectedRequest(null);
      } else {
        console.error("Failed to withdraw the request");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = (request) => {
    setSelectedRequest(request);
    setApplicants(request.applicants || []);
    console.log(request.applicants); // Log the entire applicants array
  };

  const closePopup = () => {
    setSelectedRequest(null);
    setApplicantPopupVisible(false); // Close the request-popup when closing request details
  };

  const isRequestPoster = selectedRequest && selectedRequest.sender === name;
  const isRequestAcceptor = selectedRequest && selectedRequest.acceptor == name;

  const handleAcceptApplicant = async (applicantId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request/accept-applicant/${selectedRequest._id}/${applicantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setTimeout(() => {
          setShowNotification2(true);
          setTimeout(() => {
            setShowNotification2(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
        const updatedRequest = await response.json();
        setRequests(
          requests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );
        setSelectedRequest(updatedRequest);
        alert(
          "You have accepted an applicant. The Request will be removed in 24 hours from now."
        );
        setApplicantPopupVisible(false); // Close the request-popup after accepting
      } else {
        const errorData = await response.json();
        console.error("Failed to accept the applicant:", errorData.error);
        // Optionally, display an error message to the user
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <div className="stocks-container">
      <br />

      <div className="test-12-stocks-list">
        {requests.map((request, index) => (
          <React.Fragment key={request._id}>
            <button
              className="test-12-stock-item"
              onClick={() => handleClick(request)}
            >
              <div className="flex items-center space-x-6">
                <Badge
                  variant={
                    request.acceptor !== "none" ? "success" : "secondary"
                  }
                  className={`px-3 py-1 text-xs font-semibold ${
                    request.acceptor !== "none"
                      ? "bg-green-100 text-green-800"
                      : "bg-yellow-100 text-yellow-800"
                  }`}
                >
                  {request.acceptor !== "none" ? "Accepted" : "Pending"}
                </Badge>

                <div className="stock-info">
                  <h2>{request.assignment}</h2>
                  <div className="info-details">
                    {/* <Book className="icon book-icon" /> */}
                    <GitPullRequest className="icon book-icon" />
                    {/* <SaleIcon style={{ height: "50px", width: "60px" }} /> */}
                    <span>{request.subject}</span>
                  </div>
                </div>
              </div>
              <div className="stock-value">
                <div className="value-details1">
                  <div className="icon dollar-icon" />
                  <span>₹{request.price}</span>
                </div>
                <br />
                <div className="value-details">
                  <Clock className="icon clock-icon" />
                  <span>{getDaysFromDeadline(request.deadline)}</span>
                </div>
              </div>
            </button>
            {index < requests.length - 1 && <div className="divider"></div>}
          </React.Fragment>
        ))}
      </div>

      {selectedRequest && (
        <div className="request-popup-container">
          <div className="request-popup-content">
            <div className="request-popup-header">
              <h1>{selectedRequest.assignment}</h1>
              <p className="request-popup-subtitle">
                {selectedRequest.subject}
              </p>
              <p className="request-popup-description">
                {selectedRequest.details}
              </p>
              <FontAwesomeIcon
                icon={faXmark}
                className="request-popup-close-icon"
                onClick={closePopup}
              />
            </div>
            <div className="request-popup-details">
              <p>
                <strong>Price:</strong> ₹{selectedRequest.price}
              </p>
              <p>
                <strong>Deadline:</strong> {selectedRequest.deadline}
              </p>
              <p>
                <strong>Posted By:</strong> {selectedRequest.sender}
              </p>
              <p>
                <strong>Posted At:</strong>{" "}
                {new Date(selectedRequest.createdAt).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </p>

              <p>
                <strong>Accepted By:</strong> {selectedRequest.acceptor}
              </p>
              {(isRequestPoster || isRequestAcceptor) &&
                selectedRequest.acceptor !== "none" && (
                  <button
                    onClick={() => handleChat(selectedRequest.acceptor)}
                    className="view-applicant-button"
                  >
                    Chat
                  </button>
                )}
            </div>

            {/* Show this section only if the current user is the one who posted the request */}
            {isRequestPoster && selectedRequest.acceptor === "none" && (
              <div className="request-popup-applicants">
                <h3>Applicants:</h3>
                {applicants.length > 0 ? (
                  <div className="applicants-list">
                    {applicants.map((applicant) => (
                      <div key={applicant._id} className="applicant-item">
                        <p>
                          <strong>Name:</strong> {applicant.name}
                        </p>
                        {/* <p>
                          <strong>Email:</strong> {applicant.email}
                        </p> */}
                        <button
                          onClick={() => openApplicantPopup(applicant)}
                          className="view-applicant-button"
                        >
                          View Details
                        </button>
                        <div>&nbsp;&nbsp;</div>
                        <button
                          onClick={() => handleChat(applicant.name)}
                          className="view-applicant-button"
                        >
                          Chat
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No applicants yet.</p>
                )}
                <button
                  onClick={() => handleWithdrawRequest(selectedRequest._id)}
                  className="withdraw-button"
                >
                  Withdraw Request
                </button>
              </div>
            )}

            {applicantPopupVisible && applicantToAccept && (
              <div className="applicant-request-popup">
                <div className="applicant-request-popup-content">
                  <h2>Accept Applicant</h2>

                  <p>
                    <strong>Name:</strong> {applicantToAccept.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {applicantToAccept.email}
                  </p>
                  <p>
                    <strong>Status:</strong> {applicantToAccept.status}
                  </p>
                  <button
                    onClick={() => handleAcceptApplicant(applicantToAccept._id)}
                    className="accept-applicant-button"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => setApplicantPopupVisible(false)}
                    className="close-request-popup-button"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {/* Show apply button only if the current user is not the request poster and has not applied yet */}
            {!isRequestPoster &&
              selectedRequest.acceptor === "none" &&
              !hasUserApplied() && (
                <div className="request-popup-footer">
                  <button
                    onClick={() => handleApply(selectedRequest._id)}
                    className="accept-button"
                  >
                    <FontAwesomeIcon icon={faCheck} /> Apply
                  </button>
                </div>
              )}

            {!isRequestPoster &&
              selectedRequest.acceptor === "none" &&
              hasUserApplied() && (
                <div className="request-popup-footer">
                  <button
                    onClick={() => handleChat(name)}
                    className="view-applicant-button"
                  >
                    Chat
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Applied Successfully
              </div>
            </div>
          </li>
        </ul>
      )}
      {showNotification1 && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Withdrawn Successfully
              </div>
            </div>
          </li>
        </ul>
      )}
      {showNotification2 && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Applicant Selected
              </div>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Test12;
