import axios from "axios";
import randomIndianName from "random-indian-name";
import React, { useState } from "react";
import "./FL1.css";

const FL1 = () => {
  const [count, setCount] = useState(0); // User count to generate
  const [data, setData] = useState([]); // Holds generated data
  const [message, setMessage] = useState(""); // Feedback message
  const [loading, setLoading] = useState(false); // Loading indicator

  const generateRandomEmail = (name) => {
    const domains = ["gmail.com", "yahoo.com", "outlook.com", "rediffmail.com"];
    const domain = domains[Math.floor(Math.random() * domains.length)];
    return name.toLowerCase().replace(/\s+/g, ".") + `@${domain}`;
  };

  const generateRandomPassword = () => {
    return Math.random().toString(36).slice(-8); // Generate random 8-character password
  };

  const handleGenerate = async () => {
    if (count <= 0) {
      setMessage("Please enter a valid number of users.");
      return;
    }

    setLoading(true);
    setMessage(""); // Clear previous messages
    const uniqueNames = new Set();
    const generatedData = [];

    while (uniqueNames.size < count) {
      const name = randomIndianName();
      if (!uniqueNames.has(name)) {
        uniqueNames.add(name);
        generatedData.push({
          name,
          email: generateRandomEmail(name),
          password: generateRandomPassword(),
        });
      }
    }

    setData(generatedData); // Update UI with generated data

    try {
      // Send generated users to the backend
      const serverUrl =
        process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
      const response = await axios.post(
        `${serverUrl}/api/bots/bulk-add`,
        generatedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setMessage(
        `Successfully added ${response.data.addedCount} users to the database.`
      );
    } catch (error) {
      console.error("Error adding users:", error);
      setMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fl1-container">
      <h1 className="fl1-title">Generate and Add Users</h1>
      <div className="fl1-input-group">
        <label htmlFor="count" className="fl1-label">
          Number of Users:
        </label>
        <input
          type="number"
          id="count"
          className="fl1-input"
          value={count}
          onChange={(e) => setCount(Number(e.target.value))}
          min="1"
        />
        <button
          className="fl1-button"
          onClick={handleGenerate}
          disabled={loading}
        >
          {loading ? "Processing..." : "Generate and Add"}
        </button>
      </div>
      <div className="fl1-output">
        {data.map((item, index) => (
          <div key={index} className="fl1-item">
            <p className="fl1-name">Name: {item.name}</p>
            <p className="fl1-email">Email: {item.email}</p>
            <p className="fl1-password">Password: {item.password}</p>
          </div>
        ))}
      </div>
      {message && <p className="fl1-message">{message}</p>}
    </div>
  );
};

export default FL1;
