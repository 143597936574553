import React from "react";
import "./Test18.css";

const Test18 = () => {
  return (
    <div className="test-18-card-container">
      <br />
      <div>
        <div className="test-18-heading">CLIENT TESTIMONIALS</div>
        <div className="test-18-sub-heading">
          <span className="nhmp-line-break">LOVED BY OUR</span>
          <span className="nhmp1-line-break">CUSTOMERS</span>
        </div>
        <div className="test-18-subsubheading">TRUST THEIR WORDS NOT OURS</div>
        <button className="test-18-card-button">Get started</button>
      </div>
    </div>
  );
};

export default Test18;
