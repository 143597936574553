import React from "react";
import "./Test13.css";

const Test13 = () => {
  // Testimonial data
  const testimonials = [
    {
      name: "John Doe",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 4,
      description: "This is the best service I have used. Highly recommend it!",
    },
    {
      name: "Jane Smith",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 5,
      description: "Amazing experience! Everything worked flawlessly.",
    },
    {
      name: "Mark Johnson",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 3,
      description:
        "Good, but there is room for improvement in the user interface.",
    },
    {
      name: "Emily Davis",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 5,
      description: "The customer support was excellent. A true game-changer.",
    },
    {
      name: "Michael Brown",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 4,
      description:
        "I had a wonderful experience, but there could be some improvements.",
    },
    {
      name: "Sophia Williams",
      photo: "https://via.placeholder.com/25", // Placeholder for image
      rating: 4,
      description: "Great product overall. Will definitely use it again.",
    },
  ];

  return (
    <>
    <div className="bgcolor">
      <br />
      <br />
      <div className="test-13-section-heading">CLIENT TESTIMONIALS</div>

      {/* Subheading */}
      {/* <hr styles={'width: 70vw;'}/> */}
      {/* <div className="line"></div> */}
      <br />
      <br />
      <div className="test-13-section-subheading">LOVED BY OUR CUSTOMERS</div>

      {/* Description */}
      <div className="test-13-description">TRUST THEIR WORDS NOT OURS</div>

      {/* Testimonial Cards with Horizontal Animation */}
      <br />
      <div className="test-13-testimonial-cards-container">
        <div className="test-13-testimonial-cards">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="test-13-card"
            >
              {/* Card Header */}
              <div className="test-13-card-header">
                <div className="test-13-card-photo">
                  <img src={testimonial.photo} alt="profile" />
                </div>

                <div className="test-13-card-heading-content">
                  <div className="test-13-card-title">{testimonial.name}</div>

                  {/* Card Rating */}
                  <div className="test-13-card-rating">
                    {"★".repeat(testimonial.rating)}
                    {"☆".repeat(5 - testimonial.rating)}
                  </div>
                </div>
              </div>

              {/* Card Description */}
              <div className="test-13-card-description">
                {testimonial.description}
              </div>
            </div>
          ))}
        </div>

        <div className="test-13-testimonial-cards">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`test-13-card ${index % 2 === 0 ? "offset" : ""}`}
            >
              {/* Card Header */}
              <div className="test-13-card-header">
                <div className="test-13-card-photo">
                  <img src={testimonial.photo} alt="profile" />
                </div>

                <div className="test-13-card-heading-content">
                  <div className="test-13-card-title">{testimonial.name}</div>

                  {/* Card Rating */}
                  <div className="test-13-card-rating">
                    {"★".repeat(testimonial.rating)}
                    {"☆".repeat(5 - testimonial.rating)}
                  </div>
                </div>
              </div>

              {/* Card Description */}
              <div className="test-13-card-description">
                {testimonial.description}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Read More Button at the End of Section */}
      <br /><br />
      <div className="test-13-button-container">
        <div className="test-13-button">Read More About Requesto</div>
      </div>
      <br />
      <br />
    </div>
    </>
  );
};

export default Test13;
