import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect } from "react";
import "./Fl2.css";
import { useNavigate } from "react-router-dom";

const FL2 = () => {
  const [bots, setBots] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Fetch bots from BotLog
  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/bots`
        );
        setBots(response.data);
      } catch (error) {
        setMessage("Error fetching bots.");
      }
    };

    fetchBots();
  }, []);

  // Handle deleting a bot from both databases
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/bots/${id}`);
      setBots(bots.filter((bot) => bot._id !== id));
      setMessage("Bot deleted from both databases.");
    } catch (error) {
      setMessage("Error deleting bot.");
    }
  };

  // Handle sign in for a bot
  const handleSignIn = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/signin`,
        { email, password }
      );

      const { token } = response.data;
      localStorage.setItem("token", token);

      const decodedToken = jwtDecode(token);
      localStorage.setItem("name", decodedToken.user.name);
      localStorage.setItem("email", decodedToken.user.email);
      navigate("/");

      setMessage(`Signed in successfully as: ${decodedToken.user.name}`);
    } catch (error) {
      setMessage("Error signing in.");
    }
  };

  return (
    <div className="fl2-container">
      <h1 className="fl2-title">Manage Bots</h1>
      {message && <p className="fl2-message">{message}</p>}
      {bots.length === 0 ? (
        <p className="fl2-message">No bots stored yet.</p>
      ) : (
        <div className="fl2-list">
          {bots.map((bot) => (
            <div key={bot._id} className="fl2-item">
              <p className="fl2-name">Name: {bot.name}</p>
              <p className="fl2-email">Email: {bot.email}</p>
              <p className="fl2-password">Password: {bot.password}</p>
              <button
                className="fl2-delete-button"
                onClick={() => handleDelete(bot._id)}
              >
                Delete
              </button>
              <button
                className="fl2-signin-button"
                onClick={() => handleSignIn(bot.email, bot.password)}
              >
                Sign In
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FL2;
