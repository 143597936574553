import ProfilePopup from "../ProfilePopup";
import profile from "../images/navbar_profile.png";
import logo from "./logo192.png";
import React, { useState, useEffect } from "react";
// Same profile icon as in Navbar
import { useNavigate } from "react-router-dom";

const Test8 = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("None");
  const [email, setEmail] = useState("None");

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setModalOpen(false);
    navigate("/");
  };

  // Fetch name and email from localStorage on component mount
  useEffect(() => {
    const storedName = localStorage.getItem("name") ?? "None";
    const storedEmail = localStorage.getItem("email") ?? "None";
    setName(storedName);
    setEmail(storedEmail);
  }, []);

  return (
    <div>
      <div className="test-4-navbar">
        {/* Logo */}
        <img src={logo} alt="Logo" />

        {/* Dropdown Menu */}
        <div className="test-4-menu">
          <div className="test-4-menu-icon" onClick={toggleDropdown}>
            ☰
          </div>
          <div className={`test-4-dropdown ${isDropdownOpen ? "active" : ""}`}>
            <div className="test-4-dropdown-item" onClick={toggleModal}>
              Profile
            </div>
            {/* <div className="test-4-dropdown-item">Settings</div> */}
            <div className="test-4-dropdown-item" onClick={handleLogout}>
              Logout
            </div>
          </div>
        </div>
      </div>

      {/* ProfilePopup Component */}
      {isModalOpen && (
        <ProfilePopup
          name={name}
          email={email}
          onClose={toggleModal}
          onLogout={handleLogout}
        />
      )}
    </div>
  );
};

export default Test8;
