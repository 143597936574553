import axios from "axios";
import React, { useState, useEffect } from "react";

const PoolingRequestTest = () => {
  const [poolingRequests, setPoolingRequests] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    targetAmount: "",
    deadline: "",
    creator: "",
    creator_email: "",
  });

  const fetchPoolingRequests = async () => {
    try {
      const response = await axios.get("/api/pooling-requests");
      setPoolingRequests(response.data);
    } catch (error) {
      console.error("Error fetching pooling requests:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/pooling-requests", formData);
      console.log("Pooling request created:", response.data);
      fetchPoolingRequests(); // Refresh the list
      setFormData({
        title: "",
        description: "",
        targetAmount: "",
        deadline: "",
        creator: "",
        creator_email: "",
      });
    } catch (error) {
      console.error("Error creating pooling request:", error);
    }
  };

  useEffect(() => {
    fetchPoolingRequests();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Create Pooling Request</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={formData.title}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <textarea
          name="description"
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <input
          type="number"
          name="targetAmount"
          placeholder="Target Amount"
          value={formData.targetAmount}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <input
          type="datetime-local"
          name="deadline"
          value={formData.deadline}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <input
          type="text"
          name="creator"
          placeholder="Creator Name"
          value={formData.creator}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <input
          type="email"
          name="creator_email"
          placeholder="Creator Email"
          value={formData.creator_email}
          onChange={handleChange}
          required
          style={{ display: "block", marginBottom: "10px" }}
        />
        <button type="submit" style={{ padding: "10px 20px" }}>
          Create Request
        </button>
      </form>

      <h2>Existing Pooling Requests</h2>
      {poolingRequests.length > 0 ? (
        poolingRequests.map((request) => (
          <div
            key={request._id}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <h3>{request.title}</h3>
            <p>{request.description}</p>
            <p>
              <strong>Target Amount:</strong> {request.targetAmount}
            </p>
            <p>
              <strong>Deadline:</strong>{" "}
              {new Date(request.deadline).toLocaleString()}
            </p>
            <p>
              <strong>Total Collected:</strong> {request.totalCollected}
            </p>
            <p>
              <strong>Status:</strong> {request.status}
            </p>
          </div>
        ))
      ) : (
        <p>No pooling requests found.</p>
      )}
    </div>
  );
};

export default PoolingRequestTest;
