import React from "react";
import "./Test11.css";

// Import corresponding CSS for styling

const Test11 = () => {
  return (
    <div className="test-11-container">
      <div className="test-11-image-container">
        <img
          alt="Placeholder image for the request"
          height="400"
          src="https://picsum.photos/200/300"
          width="600"
        />
        <i className="fas fa-arrow-left test-11-back-icon"></i>
        <i className="fas fa-heart test-11-heart-icon"></i>
      </div>
      <div className="test-11-details">
        <div>
          <h2>Request Title</h2>
          <div className="test-11-location">Location</div>
        </div>
        <div className="test-11-price">₹100</div>
      </div>
      <div className="test-11-description">
        <h3>Description</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet cumque
          impedit, sint maiores beatae delectus velit nemo iste. Libero
          voluptatibus error vel ullam laborum pariatur maiores cumque. Quis
          iusto eum odit consectetur.
        </p>
      </div>
      <div className="test-11-interest-container-background">
        <div className="test-11-interest-container">
          <div className="test-11-interest-container-heading">Details</div>
          <h3>Are u Interested in this request?</h3>
          <div className="test-11-interest-details">
            <div className="test-11-people">
              <img
                alt="Person 1"
                height="30"
                src="https://storage.googleapis.com/a1aa/image/HMYwlx7Qn3rbHllmf03O7eb5nXsScG4ZyiQQ0UyxIqX1N47TA.jpg"
                width="30"
              />
              <img
                alt="Person 2"
                height="30"
                src="https://storage.googleapis.com/a1aa/image/Ihnteus1fSvuZEg6BUheuHLIWPg1UvfgC0QmucydOvt42gvPB.jpg"
                width="30"
              />
              <img
                alt="Person 3"
                height="30"
                src="https://storage.googleapis.com/a1aa/image/Qmml1anOnO7GFVCDT6hGidRg2QEMUNPCrs6LLlKaRICdDe9JA.jpg"
                width="30"
              />
              <img
                alt="Person 4"
                height="30"
                src="https://storage.googleapis.com/a1aa/image/6fiIlmuqv9T6HSXnehFF8A25Q08dynRSUFicCBw3QShwN47TA.jpg"
                width="30"
              />
              <span>30+ People are interested</span>
            </div>
            <div className="test-11-buttons">
              <button>Yes, I am</button>
              <div className="test-11-icon">
                <i className="fas fa-comment"></i> 63+
              </div>
              <div className="test-11-icon">
                <i className="fas fa-heart"></i> 99+
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test11;
