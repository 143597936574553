import React, { useState, useEffect } from "react";
import "./Test7.css";
import applyRequestImg from "./applyRequestImg.png";
import browseRequestsImg from "./browseRequestsImg.png";
import chatImg from "./chatImg.png";
import chatWithPosterImg from "./chatImg.png";
import postRequestImg from "./postRequestImg.png";
import selectApplicantImg from "./selectApplicantImg.png";

const Test7 = ({ isPoster, setIsPoster }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        setActiveStep((prev) => (prev + 1) % 3);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [autoPlay]);

  const handleAccordionClick = (index) => {
    setActiveStep(index);
    setAutoPlay(false);
  };

  // Steps for Acceptor and Poster
  const acceptorSteps = [
    {
      title: "POST A REQUEST",
      content:
        "Create a detailed request outlining your requirements. Specify the task, budget, and any other relevant details to attract suitable applicants.",
      image: postRequestImg,
    },
    {
      title: "SELECT AN APPLICANT",
      content:
        "Review the applications and select the best candidate based on skills, availability, and overall suitability for your request.",
      image: selectApplicantImg,
    },
    {
      title: "CHAT WITH THE APPLICANT",
      content:
        "Start a conversation with the selected applicant to clarify any details, provide additional instructions, and ensure smooth communication throughout the task.",
      image: chatImg,
    },
  ];

  const posterSteps = [
    {
      title: "BROWSE REQUESTS",
      content:
        "Explore available requests posted by others, filtering by category, budget, and task type to find the best opportunities.",
      image: browseRequestsImg,
    },
    {
      title: "APPLY FOR A REQUEST",
      content:
        "Submit your application by outlining your skills and experience, and explain why you are the best fit for the task.",
      image: applyRequestImg,
    },
    {
      title: "CHAT WITH THE POSTER",
      content:
        "Engage in a conversation with the poster to discuss details, ask questions, and finalize the terms before starting the task.",
      image: chatWithPosterImg,
    },
  ];

  const steps = isPoster ? posterSteps : acceptorSteps;

  return (
    <div className="test-7-container">
      <h1 className="test-7-heading">
        <span className="test-7-underline">HOW IT</span>
        <span className="test-7-underline">WORKS ?</span>
      </h1>

      {/* Slider Toggle */}

      {/* Display Image and Content for Active Step */}
      <div className="test-7-content-area">
        <div className="test-7-step-image">
          <img src={steps[activeStep].image} alt={steps[activeStep].title} />
        </div>
      </div>

      <div className="test-7-accordion">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`test-7-accordion-item ${
              activeStep === index ? "test-7-active" : ""
            }`}
            onClick={() => handleAccordionClick(index)}
          >
            <div className="test-7-accordion-header">
              <span className="test-7h10">{step.title}</span>
              <span className="test-7-arrow">›</span>
            </div>
            <div className="test-7-accordion-content">
              <p>{step.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Test7;
