import React, { useState } from "react";
import "./Test4.css";
import Inbox from "../Inbox";
import CustomRequestModal from "../RequestTemplates/CustomRequestModal";
import CallForAction from "./CallForAction";
import SliderToggle from "./SliderToggle";
import Test6 from "./Test6";
import Test7 from "./Test7";
import Test8 from "./Test8";
import Test9 from "./Test9";
import Test10 from "./Test10";
import Test12 from "./Test12";
import Test13 from "./Test13";
import Test14 from "./Test14";
import Test15 from "./Test15";
import Test17 from "./Test17";
import Test18 from "./Test18";
import g3 from "./graphic3.png";

// Import Test10

const Test4 = () => {
  const [isPoster, setIsPoster] = useState(false);

  return (
    <div className="a">
      <Inbox />
      <Test8 />
      <SliderToggle isPoster={isPoster} setIsPoster={setIsPoster} />
      {/* Slider Toggle */}

      {/* Test10 below SliderToggle */}
      <div className={`hero-aboutus ${isPoster ? "poster" : "acceptor"}`}>
        <Test9 contentType={isPoster ? "acceptor" : "poster"} />
        {/* <Test17  /> */}
        <br />
        <br />
        <br />
        {/* <Test10 isPoster={isPoster} setIsPoster={setIsPoster} /> */}
        {/* <img src={g3} style={{ position: "fixed", top: 400 }} /> */}
        <Test14 />
        {/* <Test13 /> */}
      </div>
      <br />
      <br />
      <br />

      <Test18 />

      <Test7 isPoster={isPoster} setIsPoster={setIsPoster} />
      <CallForAction isPoster={isPoster} />

      <br />
      <br />
      <div className="line-divider"></div>
      <Test15 isPoster={isPoster} />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Test6 isPoster={isPoster} />
    </div>
  );
};

export default Test4;
