import React from "react";
import "./Test10.css";

const Test10 = ({ isPoster, setIsPoster }) => {
  const handleButtonClick = () => {
    setIsPoster((prevState) => !prevState);
  };

  return (
    <div className="test-10-button-container">
      <div
        className={`test-10-button test-10-black ${
          isPoster ? "test-10-black-active" : ""
        }`}
        onClick={handleButtonClick}
      >
        Earn Now!
      </div>
      <div
        className={`test-10-button test-10-grey ${
          isPoster ? "" : "test-10-grey-active"
        }`}
        onClick={handleButtonClick}
      >
        Get solutions now!
      </div>
    </div>
  );
};

export default Test10;
