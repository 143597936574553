import "./App.css";
import AssignmentRequest from "./components/AssignmentRequest/AssignmentRequest.js";
import Chatbutton from "./components/Buttons/Chatbutton";
import Chatroom from "./components/Chatroom";
import ContactUs from "./components/ContactUs";
// Add this
import Cover from "./components/Cover";
import Homepage from "./components/Homepage";
import Inbox from "./components/Inbox";
import L from "./components/L";
import MinimalistMobileMenu from "./components/MinimalistMobileMenu";
import MobileCover from "./components/MobileCover";
import MobileHomepage from "./components/MobileHomepage";
import PoolingRequestTest from "./components/PoolingRequestTest.js";
import Privacy from "./components/Privacy";
import ScrollToTop from "./components/ScrollToTop";
import Subscription from "./components/Subscription";
import TalentSkillBoard from "./components/TalentSkillBoard";
import TC from "./components/TermsAndServices";
import TestSocket from "./components/TestSocket";
import Upi from "./components/Upi";
import Vide from "./components/Video";
import WelcomePopups from "./components/WelcomePopups";
import Admin from "./components/admin/Admin.js";
import Blog from "./components/blogs/Blog";
import Blog2 from "./components/blogs/Blog2";
import Blog3 from "./components/blogs/Blog3";
import Blog4 from "./components/blogs/Blog4";
import Blog5 from "./components/blogs/Blog5";
import Blog7 from "./components/blogs/Blog7";
import Test_B from "./components/blogs/Test_B";
import Test from "./components/blogs/Tests";
import Analytics from "./components/shopkeeper/Analytics.js";
import Dashboard from "./components/shopkeeper/Dashboard.js";
import Main from "./components/shopkeeper/Main.js";
import MyOrders from "./components/shopkeeper/MyOrders.js";
import Offers from "./components/shopkeeper/Offers.js";
import OrderQueue from "./components/shopkeeper/OrderQueue.js";
import PendingApproval from "./components/shopkeeper/PendingApproval.js";
import CallForAction from "./components/tests/CallForAction.js";
import FL1 from "./components/tests/FL1.js";
import FL3 from "./components/tests/FL3.js";
import FL2 from "./components/tests/Fl2.js";
import MapTest from "./components/tests/MapTest.js";
import RequestList from "./components/tests/RequestList.js";
import RequestVincity from "./components/tests/RequestVincity.js";
import Test1 from "./components/tests/Test1.js";
import Test2 from "./components/tests/Test2.js";
import Test3 from "./components/tests/Test3.js";
import Test4 from "./components/tests/Test4.js";
import Test5 from "./components/tests/Test5.js";
import Test6 from "./components/tests/Test6.js";
import Test7 from "./components/tests/Test7.js";
import Test8 from "./components/tests/Test8.js";
import Test10 from "./components/tests/Test10.js";
import Test11 from "./components/tests/Test11.js";
import Test12 from "./components/tests/Test12.js";
import Test13 from "./components/tests/Test13.js";
import Test14 from "./components/tests/Test14.js";
import Test15 from "./components/tests/Test15.js";
import Test16 from "./components/tests/Test16.js";
import Test17 from "./components/tests/Test17.js";
import Test18 from "./components/tests/Test18.js";
import { jwtDecode } from "jwt-decode";
import { Video } from "lucide-react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import AcademicRequestModal from './components/AcademicRequestModal';

const App = () => {
  // const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Determine if the device is mobile based on screen width
  const isMobile = window.innerWidth <= 768; // Example threshold for mobile

  useEffect(() => {
    ReactGA.initialize("G-830YGE01HC"); // Use your Measurement ID here
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={isMobile ? <MobileCover /> : <Vide />}
          />
          <Route exact path="/M" element={<MobileCover />} />
          <Route exact path="/l" element={<L />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog1" element={<Blog />} />
          <Route exact path="/homepage" element={<Homepage />} />
          <Route exact path="/blog2" element={<Blog2 />} />
          <Route exact path="/blog3" element={<Blog3 />} />
          <Route exact path="/blog4" element={<Blog4 />} />
          <Route exact path="/blog5" element={<Blog5 />} />
          <Route exact path="/blog7" element={<Blog7 />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path="/cfa" element={<CallForAction />} />
          <Route exact path="/testb" element={<Test_B />} />
          <Route exact path="/mhpg" element={<MobileHomepage />} />
          <Route exact path="/inbox" element={<Inbox />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/pendingapproval" element={<PendingApproval />} />
          <Route exact path="/main" element={<Main />} />
          <Route exact path="/orderqueue" element={<OrderQueue />} />
          <Route exact path="/myorders" element={<MyOrders />} />
          <Route exact path="/pooling" element={<PoolingRequestTest />} />

          <Route
            exact
            path="/assignmentrequests"
            element={<AssignmentRequest />}
          />
          {/* <Route
            exact
            path="/talentskillboard"
            element={<TalentSkillBoard />}
          /> */}
          <Route exact path="/tc" element={<TC />} />
          {/* <Route exact path="/ac" element={<AcademicRequestModal />} /> */}
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/subscription" element={<Subscription />} />
          {/* <Route exact path="/blog4" element={<BlogPostPage />} /> */}
          {/* <Route exact path="/chatbutton" element={<Chatbutton />} /> */}
          <Route exact path="/chatroom" element={<Chatroom />} />
          <Route exact path="/maptest" element={<MapTest />} />
          <Route exact path="/test2" element={<Test2 />} />
          <Route exact path="/test3" element={<Test3 />} />
          <Route exact path="/requesto-homepage" element={<Test4 />} />
          <Route exact path="/test6" element={<Test6 />} />
          <Route exact path="/test7" element={<Test7 />} />
          <Route exact path="/test8" element={<Test8 />} />
          <Route exact path="/test10" element={<Test10 />} />
          <Route exact path="/test11" element={<Test11 />} />
          <Route exact path="/test12" element={<Test12 />} />
          <Route exact path="/test13" element={<Test13 />} />
          <Route exact path="/test14" element={<Test14 />} />
          <Route exact path="/test15" element={<Test15 />} />
          <Route exact path="/test16" element={<Test16 />} />
          <Route exact path="/test17" element={<Test17 />} />
          <Route exact path="/test18" element={<Test18 />} />
          <Route exact path="/fl1" element={<FL1 />} />
          <Route exact path="/fl2" element={<FL2 />} />
          <Route exact path="/fl3" element={<FL3 />} />
          <Route exact path="/test5" element={<Test5 />} />
          <Route exact path="/Upi" element={<Upi />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/requestvincity" element={<RequestVincity />} />
          <Route exact path="/requestlist" element={<RequestList />} />
          {/* <Route exact path="/testsocket" element={<TestSocket />} /> */}
          {/* <Route exact path="/wc" element={<WelcomePopups />} /> */}
          {/* <Route exact path="/menu" element={<MinimalistMobileMenu />} /> */}
          {/* <Route exact path="/vido2" element={<Vide />} /> */}
          {/* <Route exact path="/contact" element={<ContactUs />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
