import React from 'react';

const Notification = ({ showNotification, message }) => {
  return (
    showNotification && (
      <ul className="notification-list">
        <li
          aria-live="polite"
          aria-atomic="true"
          role="status"
          tabIndex="0"
          className="style-0"
        >
          <div data-icon="" className="style-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="style-2"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
                className="style-3"
              />
            </svg>
          </div>
          <div data-content="" className="style-4">
            <div data-title="" className="style-5">
              {message}
            </div>
          </div>
        </li>
      </ul>
    )
  );
};

export default Notification;