import React, { useState } from "react";
import "./Test16.css";

const Test16 = () => {
  const [visible, setVisible] = useState(true);
  const [animating, setAnimating] = useState(false);

  const toggleVisibility = () => {
    if (!animating) {
      setAnimating(true);
      setVisible(!visible);
      setTimeout(() => setAnimating(false), 500); // Animation duration
    }
  };

  return (
    <div className="test-16-container">
      <button onClick={toggleVisibility} className="test-16-button">
        {/* Button text can be empty since it's a circle */}
      </button>
      <div className={`test-16-circle ${visible ? "visible" : "hidden"}`}>
        <div className="test-16-donut-sector test-16-sector1">Earn</div>
        <div className="test-16-donut-sector test-16-sector2">Post</div>
        <div className="test-16-donut-sector test-16-sector3">Save</div>
      </div>
    </div>
  );
};

export default Test16;
