import { Clock, GitPullRequest } from "lucide-react";
import React from "react";

const Badge = ({ children, variant = "secondary" }) => {
  const badgeClass =
    variant === "success" ? "badge badge-success" : "badge badge-secondary";

  return <span className={badgeClass}>{children}</span>;
};
const getDaysFromDeadline = (deadline) => {
  const days = deadline.split(" ")[0];
  return `${days} days`;
};

const RequestItem = ({ request, handleClick }) => {
  return (
    <React.Fragment key={request._id}>
      <button className="stock-item" onClick={() => handleClick(request)}>
        <div className="flex items-center space-x-6">
          <Badge
            variant={request.acceptor !== "none" ? "success" : "secondary"}
            className={`px-3 py-1 text-xs font-semibold ${
              request.acceptor !== "none"
                ? "bg-green-100 text-green-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            {request.acceptor !== "none" ? "Accepted" : "Pending"}
          </Badge>

          <div className="stock-info">
            <h2>{request.assignment}</h2>
            <div className="info-details">
              <GitPullRequest className="icon book-icon" />
              <span>{request.subject}</span>
            </div>
          </div>
        </div>
        <div className="stock-value">
          <div className="value-details1">
            <div className="icon dollar-icon" />
            <span>₹{request.price}</span>
          </div>
          <br />
          <div className="value-details">
            <Clock className="icon clock-icon" />
            <span>{getDaysFromDeadline(request.deadline)}</span>
          </div>
        </div>
      </button>
    </React.Fragment>
  );
};

export default RequestItem;
