import React, { useState, useEffect } from "react";
import "./Test5.css";
import CustomRequestModal from "../RequestTemplates/CustomRequestModal";

import videosrc from "./vid.mp4";
import { Link } from "react-router-dom";

const Test5 = () => {
  const [isCustomRequestModalOpen, setIsCustomRequestModalOpen] =
    useState(false);
  const sentences = [
    "Hiii!",
    "Welcome to Requesto!",
    "Accept Requests",
    "Earn Money!",
    "Post Requests",
    "Get Solutions Fast!",
  ];

  const [text, setText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [sentenceIndex, setSentenceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping) {
        if (text.length < sentences[sentenceIndex].length) {
          setText(
            (prevText) => prevText + sentences[sentenceIndex][text.length]
          );
        } else {
          setIsTyping(false);
        }
      } else {
        if (text.length > 0) {
          setText((prevText) => prevText.slice(0, -1));
        } else {
          setIsTyping(true);
          setSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [text, isTyping, sentenceIndex, sentences]);

  return (
    <div className="a">
      <div className="test-5-navbar">Requesto</div>
      <div className="test-5-homepage">
        {/* Video Section */}
        <div className="test-5-video-container">
          <video className="test-5-video" autoPlay muted loop playsInline>
            <source src={videosrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Typing Text Section */}
        <div className="test-5-text-section">
          <h1 className="test-5-heading">{text}</h1>
        </div>

        {/* Additional Content */}
        <div className="test-5-additional-content">
          <p className="test-5-description">
            Get started now by posting your requests or applying to earn money!
          </p>
        </div>

        {/* Buttons Section */}
        <div className="test-5-buttons">
          <button
            onClick={() => setIsCustomRequestModalOpen(true)}
            className="test-5-post-now-btn"
          >
            Post Now
          </button>
          <Link to="/assignmentrequests" className="test-5-apply-now-btn">
            Apply Now
          </Link>
        </div>
      </div>

     

      {/* Modal */}
      <CustomRequestModal
        showModal={isCustomRequestModalOpen}
        closeModal={() => setIsCustomRequestModalOpen(false)}
      />
    </div>
  );
};

export default Test5;
