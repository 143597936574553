import CustomRequestModal from "../RequestTemplates/CustomRequestModal";
import arrow from "./arrow.png";
import React from "react";
import { Link } from "react-router-dom";
import "./CallForAction.css";

const CallForAction = ({ isPoster }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <div className="nhmp-card-container">
      <div className="nhmp-card-content">
        <div className="nhmp-card-title">
          <br />
          <span className="nhmp-line-break">
            {isPoster ? "Start" : "Get Ready To"}
          </span>
          <span className="nhmp-line-break">
            {isPoster ? "Earning Now" : "Save Money"}
          </span>
        </div>

        {isPoster ? (
          <Link to="/assignmentrequests" className="nhmp-card-button">
            Earn Now
          </Link>
        ) : (
          <button
            className="nhmp-card-button"
            onClick={() => setIsModalOpen(true)}
          >
            Post Now
          </button>
        )}
      </div>

      {/* Modal for "Post Now" */}
      {isPoster || (
        <CustomRequestModal
          showModal={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default CallForAction;
