import React from "react";
import "./Test6.css";
import { Instagram, Linkedin } from "lucide-react";

const Test6 = ({ isPoster }) => {
  return (
    <footer className={`test-6-footer ${isPoster ? "poster" : "acceptor"}`}>
      <br />
      <br />
      <div className="test-6-section test-6-follow">
        <h4 className="test-6-heading">Follow Us</h4>
        <div className="test-6-circle-group">
          {/* <div>
            <a
              href="https://www.instagram.com/requesto.in?igsh=cHJxdnh6OTB6azVj"
              className="a1-social-link"
            >
              <Instagram size={24} />
              <span className="a1-sr-only">Instagram</span>
            </a>
          </div> */}
          <div>
            <a
              href="https://www.linkedin.com/company/103684563/"
              className="a1-social-link"
            >
              <Linkedin size={24} />
              <span className="a1-sr-only">LinkedIn</span>
            </a>
          </div>
          {/* <div className="test-6-circle"></div>
          <div className="test-6-circle"></div> */}
        </div>
      </div>

      <div className="test-6-divider"></div>

      <div className="test-6-content">
        <div className="test-6-section test-6-mobile">
          <h4 className="test-6-heading">Mobile App</h4>
          <p className="test-6-contact-info">Going to be announced soon...</p>

          {/* <div className="test-6-circle-group">
            <div className="test-6-circle"></div>
            <div className="test-6-circle"></div>
          </div> */}
        </div>

        <div className="test-6-section test-6-contact">
          <h4 className="test-6-heading">Contact us</h4>
          <p className="test-6-contact-info">+91 9627287643</p>
          <p className="test-6-contact-info">+91 8824014907</p>
          <p className="test-6-contact-info">+91 8287036402</p>
        </div>
      </div>

      <div className="test-6-divider"></div>

      <div className="test-6-links">
        <p>privacy policy</p>
        <p>Terms Of Use</p>
        <p>Join us and Contribute</p>
      </div>

      <p className="test-6-copyright">© 2023 - 2024 Requesto Inc.</p>
      <br />
      <br />

      <h2 className="test-6-brand">REQUESTO</h2>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </footer>
  );
};

export default Test6;
