import React, { useState, useEffect } from "react";
import "./Navbar.css";
import ProfilePopup from "./ProfilePopup";
import profile from "./images/navbar_profile.png";
import logo from "./images/r1.jpg";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("None");
  const [email, setEmail] = useState("None");

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const navigate = useNavigate();

  // Fetch name and email from localStorage on component mount
  useEffect(() => {
    const storedName = localStorage.getItem("name") ?? "None";
    const storedEmail = localStorage.getItem("email") ?? "None";
    setName(storedName);
    setEmail(storedEmail);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setModalOpen(false);
    navigate("/");
  };

  return (
    <div className="navbar">
      {/* Logo aligned to the left */}
      <div className="navbar-title">
        <Link to="/homepage">
          <img src={logo} alt="Requesto Logo" className="navbar-logo" />
        </Link>
      </div>

      {/* Profile Icon aligned to the right */}
      <img
        src={profile}
        alt="Profile"
        className="navbar-logo1"
        onClick={toggleModal}
      />

      {/* ProfilePopup Component */}
      {isModalOpen && (
        <ProfilePopup
          name={name}
          email={email}
          onClose={toggleModal}
          onLogout={handleLogout}
        />
      )}
    </div>
  );
};

export default Navbar;
